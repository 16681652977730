@tailwind base;
@tailwind components;
@tailwind utilities;

$primary-green: #00312D;
$secondary-green: #72BC0D;

iframe {
  width: 100%;
  min-height: 450px;
}

@media (max-width: 767px) {
  iframe {min-height: 300px;}
}

// global classes
.bg-primary-green {
  background-color: $primary-green;
}
.bg-secondary-green {
  background-color: $secondary-green;
}
.text-primary {
  color: $primary-green;
}

.text-secondary {
  color: $secondary-green;
}

.price {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

@font-face {
  font-family: 'merriweather-bold';
  src: url('../src/assets/fonts/Merriweather/Merriweather-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'merriweather-regular';
  src: url('../src/assets/fonts/Merriweather/Merriweather-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'merriweather-light';
  src: url('../src/assets/fonts/Merriweather/Merriweather-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: $primary-green;
  margin: 0;
  font-family: merriweather-regular ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
